import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 3000 3000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
		<g viewBox="0 0 980.000000 980.000000"  transform="">

		<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
	d="
M1314.000000,3002.000000 
	C876.000061,3002.000000 439.000153,3002.000000 2.000176,3002.000000 
	C2.000117,2002.000244 2.000117,1002.000488 2.000059,2.000578 
	C1001.999573,2.000386 2001.999146,2.000386 3001.999023,2.000193 
	C3001.999512,1001.999329 3001.999512,2001.998657 3001.999756,3001.999023 
	C2439.666748,3002.000000 1877.333374,3002.000000 1314.000000,3002.000000 
M919.015625,1670.999878 
	C919.015503,1681.665649 918.912415,1692.332520 919.044495,1702.996704 
	C919.174927,1713.523682 918.415955,1724.107422 919.610352,1734.704346 
	C970.221619,1734.704346 1019.754944,1734.704346 1069.924683,1734.704346 
	C1070.114746,1731.223633 1070.413696,1728.305542 1070.413208,1725.387573 
	C1070.394165,1626.391357 1070.393677,1527.394897 1070.253662,1428.398926 
	C1070.232056,1413.071655 1069.982422,1397.720215 1069.087280,1382.423950 
	C1067.180054,1349.834351 1064.760254,1317.275024 1062.652222,1284.696655 
	C1062.568237,1283.399658 1063.525879,1282.035156 1063.998169,1280.702148 
	C1106.191284,1432.069824 1146.050415,1583.557495 1186.438232,1734.694702 
	C1231.737427,1734.694702 1275.591553,1734.694702 1320.095215,1734.694702 
	C1324.680542,1717.613403 1329.183716,1700.936523 1333.630615,1684.244507 
	C1338.174316,1667.188232 1342.666626,1650.118042 1347.174561,1633.052246 
	C1351.598145,1616.305420 1356.002075,1599.553589 1360.435059,1582.809326 
	C1364.867920,1566.064575 1369.328125,1549.327026 1373.772095,1532.585327 
	C1378.301392,1515.521973 1382.845825,1498.462769 1387.346436,1481.391724 
	C1391.761719,1464.644409 1396.101196,1447.877197 1400.527344,1431.132812 
	C1404.953857,1414.387085 1409.454468,1397.661011 1413.907959,1380.922485 
	C1418.447266,1363.862061 1422.874756,1346.771240 1427.525024,1329.741211 
	C1431.995239,1313.371948 1435.411621,1296.669312 1442.273682,1280.901489 
	C1441.626465,1331.170410 1439.079102,1381.191772 1438.148438,1431.243042 
	C1437.206787,1481.890991 1437.848633,1532.568604 1437.827271,1583.233276 
	C1437.805908,1633.496094 1437.822510,1683.758911 1437.822510,1733.807739 
	C1448.567017,1736.142822 1585.810791,1735.766602 1593.030640,1733.592163 
	C1593.030640,1513.438110 1593.030640,1293.284058 1593.030640,1072.650146 
	C1519.105103,1072.650146 1445.900879,1072.650146 1371.809326,1072.650146 
	C1365.538086,1095.870972 1359.306152,1118.962402 1353.065186,1142.051392 
	C1346.720947,1165.522705 1340.356201,1188.988403 1334.021851,1212.462402 
	C1327.773804,1235.616577 1321.569702,1258.782593 1315.314819,1281.934814 
	C1308.973755,1305.406128 1302.563599,1328.858887 1296.239990,1352.334839 
	C1290.003052,1375.488403 1284.081421,1398.730225 1277.570801,1421.806030 
	C1271.054321,1444.902100 1265.751831,1468.373657 1258.231812,1491.173706 
	C1257.438354,1491.136597 1256.644897,1491.099487 1255.851318,1491.062378 
	C1218.230713,1351.655273 1180.610229,1212.248169 1142.963135,1072.743408 
	C1068.019165,1072.743408 994.188171,1072.743408 919.016968,1072.743408 
	C919.016968,1271.876709 919.016968,1470.438232 919.015625,1670.999878 
M1880.994507,1735.304810 
	C1911.363159,1735.459839 1941.608887,1734.216064 1971.472168,1727.990479 
	C2063.307373,1708.845337 2128.278320,1656.358643 2165.933594,1570.531128 
	C2187.947998,1520.353516 2196.165283,1467.103149 2197.613770,1412.785156 
	C2198.260986,1388.518555 2197.421143,1364.132324 2195.766113,1339.902222 
	C2192.907715,1298.054565 2183.707275,1257.531494 2166.077148,1219.260254 
	C2141.461182,1165.824829 2103.084229,1125.665894 2049.732666,1100.315918 
	C2009.589233,1081.241821 1966.777832,1073.144775 1922.768921,1072.569214 
	C1855.120239,1071.684570 1787.453003,1072.223145 1719.793579,1072.202393 
	C1717.569336,1072.201660 1715.344727,1072.684937 1712.746826,1072.985840 
	C1712.746826,1293.680054 1712.746826,1513.801758 1712.746826,1735.304077 
	C1768.549194,1735.304077 1823.772827,1735.304077 1880.994507,1735.304810 
M763.000000,1346.200806 
	C726.016479,1346.062866 689.032959,1345.926025 652.049438,1345.768188 
	C651.767517,1345.766968 651.467102,1345.504639 651.210999,1345.315918 
	C650.944824,1345.119629 650.721985,1344.864502 650.330078,1344.492676 
	C650.330078,1299.836304 650.330078,1255.041382 650.330078,1209.060303 
	C708.885010,1209.060303 766.704712,1209.060303 824.256775,1209.060303 
	C826.706360,1197.312744 826.222778,1079.644775 823.741211,1072.166748 
	C714.658691,1072.166748 605.365784,1072.162964 496.072906,1072.189209 
	C493.426147,1072.189819 490.778046,1072.507080 488.134888,1072.728149 
	C487.870392,1072.750244 487.626862,1073.121338 487.389954,1073.347534 
	C487.151123,1073.575562 486.932556,1073.824707 486.705109,1074.064697 
	C484.794525,1094.670410 485.709381,1727.034912 487.660431,1734.400879 
	C502.771851,1736.568359 642.171021,1735.720215 649.979675,1733.045532 
	C649.979675,1650.259888 649.979675,1567.093750 649.979675,1482.850586 
	C704.558472,1482.850586 758.057190,1482.850586 812.266846,1482.850586 
	C812.266846,1436.983887 812.266846,1392.205811 812.266846,1346.200928 
	C796.132141,1346.200928 780.566040,1346.200928 763.000000,1346.200806 
M2472.052490,1747.128784 
	C2481.820557,1729.318848 2492.197510,1711.811890 2501.239258,1693.640503 
	C2525.221191,1645.443970 2542.688232,1594.883911 2554.739258,1542.408569 
	C2564.085449,1501.711792 2569.073975,1460.381104 2571.793457,1418.849854 
	C2574.168945,1382.575562 2571.883301,1346.249146 2567.573975,1310.157959 
	C2558.946777,1237.905273 2539.730225,1168.549805 2509.866943,1102.135864 
	C2480.651611,1037.161987 2441.345215,978.846069 2394.233887,925.626282 
	C2391.255859,922.262024 2387.920654,919.213867 2384.171875,915.434265 
	C2361.040283,940.460571 2337.112305,962.980042 2314.866455,987.707581 
	C2317.436523,991.082703 2319.151123,993.467773 2321.001465,995.742371 
	C2330.669922,1007.627441 2340.729736,1019.212891 2349.998779,1031.400513 
	C2381.207764,1072.435913 2406.791504,1116.739136 2426.397705,1164.469238 
	C2448.312256,1217.818481 2462.478271,1273.218262 2467.828369,1330.554443 
	C2472.354736,1379.064209 2471.761475,1427.677490 2464.304199,1476.029419 
	C2455.570312,1532.660889 2439.581299,1587.124878 2415.499023,1639.120361 
	C2391.535889,1690.858521 2360.022705,1737.724854 2322.834473,1780.856323 
	C2320.097900,1784.030518 2317.758545,1787.547119 2315.929932,1789.979370 
	C2340.146973,1813.937134 2363.467285,1837.007568 2387.455322,1860.738892 
	C2418.581787,1826.631836 2446.941650,1789.017822 2472.052490,1747.128784 
M2204.406982,1680.593018 
	C2212.651367,1688.843628 2220.802490,1697.190430 2229.166504,1705.317993 
	C2237.648682,1713.559937 2245.461426,1722.552246 2254.939453,1730.289551 
	C2257.724854,1727.377563 2259.831299,1725.286133 2261.814941,1723.084351 
	C2284.230957,1698.204468 2302.447021,1670.362183 2319.330078,1641.572998 
	C2357.016357,1577.308960 2379.781738,1508.152344 2384.641602,1433.789917 
	C2387.353516,1392.291626 2387.382080,1350.726074 2380.037109,1309.291992 
	C2368.275879,1242.946899 2346.829834,1180.436035 2311.428223,1122.943848 
	C2296.906494,1099.360474 2280.864502,1076.847900 2262.653320,1055.952026 
	C2256.171143,1048.514282 2252.389160,1048.467529 2245.032227,1054.891479 
	C2243.278809,1056.422729 2241.681885,1058.137329 2240.056396,1059.809814 
	C2222.874268,1077.490479 2205.685303,1095.164307 2188.549316,1112.889648 
	C2186.765381,1114.735107 2185.311279,1116.899536 2183.400879,1119.296265 
	C2250.067383,1197.071655 2283.856201,1286.816650 2284.393066,1388.785278 
	C2284.933350,1491.417236 2248.364258,1580.461914 2183.107910,1659.185913 
	C2190.303467,1666.417480 2196.856689,1673.003418 2204.406982,1680.593018 
M1105.197266,2113.841064 
	C1113.765259,2113.841064 1122.333374,2113.841064 1131.861450,2113.841064 
	C1131.861450,2076.224854 1131.861450,2039.774902 1131.861450,2003.324829 
	C1132.464722,2003.256104 1133.068115,2003.187256 1133.671387,2003.118530 
	C1143.485596,2039.924683 1153.299683,2076.730957 1163.073120,2113.384277 
	C1175.829224,2113.384277 1187.403931,2113.384277 1199.481201,2113.384277 
	C1210.505859,2075.279785 1219.357056,2036.860107 1231.661499,1999.423340 
	C1231.661499,2037.261719 1231.661499,2075.100098 1231.661499,2113.227051 
	C1245.769409,2113.227051 1259.251831,2113.227051 1272.615601,2113.227051 
	C1274.750366,2101.347900 1274.255005,1941.448975 1272.178589,1934.494263 
	C1252.649780,1934.494263 1233.132446,1934.494263 1213.198730,1934.494263 
	C1203.267822,1971.198486 1193.470947,2007.407837 1183.673950,2043.617065 
	C1182.942871,2043.686279 1182.211792,2043.755493 1181.480591,2043.824585 
	C1176.510498,2025.702759 1171.499268,2007.592041 1166.581543,1989.456055 
	C1161.623779,1971.172119 1156.758667,1952.863159 1151.835327,1934.499390 
	C1131.219971,1934.499390 1111.415771,1934.499390 1091.729736,1934.499390 
	C1089.505249,1947.811523 1090.158325,2106.546875 1092.541870,2113.840576 
	C1095.992432,2113.840576 1099.634766,2113.840576 1105.197266,2113.841064 
M2144.685791,1994.492554 
	C2136.386963,1955.333252 2111.801025,1934.832520 2071.844482,1933.945801 
	C2060.863281,1933.702026 2049.873291,1933.864502 2038.887207,1933.850830 
	C2031.288330,1933.841309 2023.689575,1933.849121 2015.465454,1933.849121 
	C2015.465454,1994.143311 2015.465454,2053.528076 2015.465454,2113.231445 
	C2018.020386,2113.467529 2019.959351,2113.798096 2021.898804,2113.802002 
	C2037.545654,2113.833252 2053.195801,2114.007812 2068.838623,2113.750244 
	C2105.963623,2113.138916 2132.603516,2092.454834 2142.491943,2056.537842 
	C2147.966553,2036.651733 2148.186035,2016.454834 2144.685791,1994.492554 
M1550.400879,1972.014771 
	C1543.306885,1971.589844 1536.108276,1971.827026 1530.178467,1975.987793 
	C1524.669922,1979.852905 1519.930664,1984.814453 1513.835693,1990.190186 
	C1515.901855,1966.880737 1515.514282,1944.812500 1515.065674,1922.735718 
	C1500.132690,1922.735718 1486.607666,1922.735718 1473.203979,1922.735718 
	C1470.944824,1934.755493 1471.502319,2106.452393 1473.901855,2113.838623 
	C1480.006592,2113.838623 1486.388916,2113.194824 1492.587280,2113.987793 
	C1502.108765,2115.206299 1508.673462,2112.528809 1511.685547,2102.952148 
	C1511.914429,2102.224365 1513.109009,2101.800049 1514.241699,2100.941406 
	C1515.824219,2102.314941 1517.520752,2103.382568 1518.690918,2104.865234 
	C1531.842773,2121.531738 1565.224365,2121.403320 1578.133789,2097.274414 
	C1581.248535,2091.452637 1584.122070,2085.303467 1585.822266,2078.960693 
	C1591.521484,2057.699707 1591.798584,2036.059204 1587.188110,2014.645874 
	C1585.331665,2006.024292 1581.606079,1997.565796 1577.398804,1989.750488 
	C1572.121216,1979.946899 1563.467285,1973.800659 1550.400879,1972.014771 
M2281.140625,1985.944824 
	C2273.554688,1977.726929 2263.988770,1973.290161 2253.034180,1972.339111 
	C2235.226562,1970.792847 2217.804199,1972.813232 2201.178955,1979.779175 
	C2197.934082,1981.139038 2194.896729,1982.994385 2191.333008,1984.844849 
	C2195.294678,1995.329224 2198.887451,2004.837036 2202.747070,2015.051270 
	C2207.245117,2013.135864 2210.484375,2011.455811 2213.910889,2010.365601 
	C2219.586670,2008.559814 2225.294678,2006.400024 2231.146484,2005.695190 
	C2244.685059,2004.064209 2249.527100,2009.316162 2248.598877,2024.313721 
	C2245.478760,2024.564697 2242.217285,2024.647217 2239.012695,2025.117432 
	C2227.868652,2026.753174 2216.466797,2027.518555 2205.684326,2030.500854 
	C2192.670410,2034.100342 2183.981689,2043.363525 2180.852051,2056.784912 
	C2177.668945,2070.435059 2178.698975,2083.947510 2184.397217,2096.855713 
	C2188.034668,2105.094971 2193.838135,2111.706543 2202.677979,2114.151367 
	C2218.631836,2118.563721 2233.719971,2117.263672 2246.139893,2104.742432 
	C2248.633301,2102.228516 2250.791748,2099.382080 2253.982422,2095.667480 
	C2257.341064,2102.723877 2259.849609,2107.994141 2262.442139,2113.440918 
	C2272.344727,2113.440918 2281.909912,2113.440918 2291.798584,2113.440918 
	C2292.223389,2111.212646 2292.900879,2109.306885 2292.903564,2107.399902 
	C2292.944336,2077.782715 2293.109863,2048.163330 2292.764648,2018.549927 
	C2292.631104,2007.076782 2289.850830,1996.110962 2281.140625,1985.944824 
M2438.558838,2080.080566 
	C2439.693359,2084.595215 2440.472412,2089.236572 2442.027100,2093.601562 
	C2447.295654,2108.390869 2458.227783,2115.808594 2473.855225,2116.280029 
	C2488.561768,2116.723145 2500.613770,2111.844971 2509.514648,2099.934814 
	C2510.495361,2098.622314 2510.981201,2096.939941 2512.322021,2094.107910 
	C2516.250488,2102.471191 2518.891846,2108.094971 2521.281982,2113.183594 
	C2531.807617,2113.183594 2541.323975,2113.183594 2551.827637,2113.183594 
	C2551.827637,2109.284180 2551.827637,2106.033203 2551.827637,2102.782471 
	C2551.827148,2079.123535 2551.836426,2055.464355 2551.818848,2031.805420 
	C2551.814941,2026.474609 2551.972656,2021.126831 2551.620850,2015.815674 
	C2549.814209,1988.536865 2532.945312,1972.250610 2505.660645,1972.107788 
	C2496.062744,1972.057739 2486.353027,1973.002686 2476.883545,1974.609009 
	C2467.774902,1976.154053 2458.842773,1978.975220 2450.502930,1984.986084 
	C2454.004883,1995.016724 2457.435059,2004.851929 2462.036865,2014.844849 
	C2466.056641,2013.130371 2468.980469,2011.557007 2472.095947,2010.622437 
	C2478.445801,2008.717529 2484.810547,2006.333496 2491.328857,2005.629639 
	C2504.118164,2004.248413 2509.181396,2010.466064 2506.969482,2024.428223 
	C2503.571289,2024.647583 2499.979980,2024.853516 2496.392578,2025.115601 
	C2485.104248,2025.940674 2473.779785,2026.382935 2463.070801,2030.812866 
	C2447.604248,2037.210693 2439.677490,2048.928711 2438.359619,2065.321533 
	C2438.014160,2069.621826 2438.352783,2073.976562 2438.558838,2080.080566 
M748.066650,2090.955811 
	C748.066650,2084.629639 748.077332,2078.303223 748.064697,2071.977051 
	C748.027588,2053.331543 748.446594,2034.670654 747.824707,2016.044434 
	C746.985779,1990.917603 731.304382,1974.190552 707.320496,1972.231201 
	C690.175781,1970.830322 673.400085,1972.923950 657.328064,1979.327148 
	C653.746643,1980.754028 650.390869,1982.747192 646.535828,1984.677612 
	C650.544556,1995.147095 654.186157,2004.657837 658.097412,2014.872803 
	C661.983032,2013.241699 664.882874,2011.677979 667.977112,2010.795898 
	C674.978027,2008.800049 681.992126,2006.174438 689.151306,2005.561279 
	C701.056030,2004.541870 706.068604,2011.813110 702.851135,2024.498413 
	C699.727783,2024.695312 696.410828,2024.665283 693.171753,2025.152344 
	C682.358093,2026.778564 671.323853,2027.687622 660.842651,2030.573242 
	C649.416626,2033.719116 641.014893,2041.500488 637.269958,2053.155518 
	C632.258850,2068.750977 633.391602,2084.158691 640.734192,2098.748779 
	C646.425781,2110.058105 655.988342,2115.751709 668.866333,2116.287109 
	C683.867249,2116.910889 696.358154,2112.397217 705.718201,2100.299561 
	C706.701660,2099.028320 707.908203,2097.929688 709.612427,2096.108398 
	C712.550415,2102.325928 715.201355,2107.935791 717.702759,2113.229492 
	C727.944031,2113.229492 737.459778,2113.229492 748.067017,2113.229492 
	C748.067017,2106.084229 748.067017,2099.511475 748.066650,2090.955811 
M1323.750732,1998.922363 
	C1310.035522,2027.330811 1309.356201,2056.249268 1322.626099,2084.668945 
	C1333.745605,2108.483643 1353.971313,2117.883545 1379.835571,2116.135742 
	C1404.080322,2114.497314 1420.228149,2101.911377 1428.543823,2079.525146 
	C1436.676636,2057.631592 1437.096069,2035.169800 1430.194336,2012.888672 
	C1423.306885,1990.652832 1408.454346,1976.087158 1385.134521,1972.729858 
	C1360.493042,1969.182129 1338.663818,1974.703003 1323.750732,1998.922363 
M1907.639160,2056.891602 
	C1908.251953,2055.778564 1909.432007,2054.644043 1909.390747,2053.555664 
	C1908.911621,2040.931274 1908.952637,2028.223511 1907.350708,2015.730713 
	C1906.489380,2009.012817 1903.631592,2002.123535 1900.108398,1996.251221 
	C1887.510742,1975.253052 1867.350098,1970.287964 1844.760620,1972.463501 
	C1822.738525,1974.584473 1807.576904,1986.606689 1800.313965,2007.412109 
	C1792.171143,2030.737549 1792.090332,2054.582764 1799.233765,2078.185303 
	C1803.548950,2092.443115 1812.009033,2104.152100 1826.078735,2110.080811 
	C1849.770508,2120.064453 1873.600586,2118.169678 1897.012085,2108.620117 
	C1899.053223,2107.787354 1901.581299,2105.049316 1901.681641,2103.083740 
	C1902.167358,2093.569580 1901.900635,2084.016846 1901.900635,2072.968262 
	C1896.845459,2075.010742 1893.226807,2076.425781 1889.645020,2077.928467 
	C1878.932129,2082.422852 1867.736816,2083.787109 1856.327148,2082.279297 
	C1843.821167,2080.626709 1836.943237,2070.709473 1839.271118,2057.326660 
	C1861.361084,2057.326660 1883.632080,2057.326660 1907.639160,2056.891602 
M598.950562,2036.260986 
	C598.850098,2027.079346 598.749695,2017.897705 598.637573,2007.647705 
	C583.416748,2007.647705 569.572510,2007.647705 555.697510,2007.647705 
	C555.697510,1995.062134 555.697510,1983.625244 555.697510,1971.162598 
	C571.371216,1971.162598 586.223816,1971.162598 601.600159,1971.162598 
	C601.600159,1958.359375 601.600159,1946.611816 601.600159,1934.978516 
	C588.873413,1932.916626 517.705017,1933.445068 510.786652,1935.540039 
	C510.786652,1994.653564 510.786652,2053.767090 510.786652,2113.148438 
	C525.581116,2113.148438 539.683228,2113.148438 554.477295,2113.148438 
	C555.560120,2090.463623 554.064331,2068.317871 555.374268,2045.520264 
	C570.304932,2045.520264 583.873657,2045.520264 597.835571,2045.520264 
	C598.281006,2042.527710 598.612915,2040.297852 598.950562,2036.260986 
M832.257812,2077.804932 
	C828.504272,2078.783936 824.623169,2080.909912 821.022888,2080.509766 
	C812.826843,2079.598389 804.706177,2077.698242 796.679382,2075.700195 
	C792.285461,2074.606689 788.144714,2072.495605 782.458679,2070.287842 
	C782.458679,2080.986816 782.411072,2090.502686 782.472595,2100.018066 
	C782.529602,2108.839355 782.633667,2108.976074 791.344910,2111.726562 
	C807.199463,2116.732422 823.476746,2117.436279 839.773315,2115.274170 
	C851.663574,2113.696777 862.118530,2108.660645 869.196289,2098.332520 
	C882.829102,2078.438477 878.130005,2049.209473 858.608582,2035.082520 
	C851.634827,2030.035889 843.775940,2026.213013 836.326477,2021.822510 
	C833.462097,2020.134277 830.129822,2018.885254 827.907043,2016.573975 
	C826.090881,2014.685669 824.582397,2011.380127 824.902222,2008.962891 
	C825.107056,2007.414795 828.966675,2005.132446 831.040588,2005.278076 
	C837.262329,2005.714722 843.521606,2006.769897 849.553894,2008.379517 
	C854.248291,2009.632202 858.624634,2012.076660 864.120361,2014.406006 
	C867.823975,2003.904053 871.294861,1994.062256 874.903564,1983.829346 
	C853.021362,1971.926392 831.330261,1968.063965 808.478821,1975.397095 
	C792.134155,1980.642090 783.564514,1992.617676 782.436462,2009.612183 
	C781.116699,2029.495239 789.112305,2044.512817 806.904480,2053.943604 
	C813.666382,2057.527588 820.657715,2060.694824 827.303040,2064.476562 
	C831.745728,2067.004639 835.732178,2070.296143 832.257812,2077.804932 
M985.871826,1974.842896 
	C977.319031,1974.450562 968.766235,1974.058105 959.552551,1973.635376 
	C959.125061,1964.299316 958.725098,1955.565063 958.299011,1946.259766 
	C949.451660,1946.259766 941.796509,1945.969971 934.189758,1946.448853 
	C932.102539,1946.580322 929.722717,1948.665649 928.277527,1950.488892 
	C926.905701,1952.219482 926.395325,1954.734009 925.828491,1956.984985 
	C922.796082,1969.026367 917.723755,1979.379639 905.308289,1984.579224 
	C903.261353,1985.436523 900.971375,1988.276367 900.739319,1990.411499 
	C900.109070,1996.211304 900.523071,2002.124756 900.523071,2008.260254 
	C906.071106,2008.851929 910.277649,2009.300537 915.682068,2009.876831 
	C915.682068,2013.384033 915.679565,2016.625488 915.682495,2019.866821 
	C915.698303,2037.198975 915.349854,2054.542725 915.901184,2071.857910 
	C916.141602,2079.405029 917.590027,2087.072510 919.631775,2094.365479 
	C922.429871,2104.359619 929.601379,2110.934082 939.480591,2113.824707 
	C954.744385,2118.291016 969.828247,2117.115234 984.340210,2110.547852 
	C986.226196,2109.694580 988.832825,2107.600586 988.889954,2106.002197 
	C989.239441,2096.223633 990.313477,2086.352051 988.229004,2075.955566 
	C983.994568,2077.123291 980.544128,2078.171875 977.045532,2079.022461 
	C964.636536,2082.039795 959.242004,2078.120361 959.065186,2065.434326 
	C958.837524,2049.105225 958.965637,2032.770264 959.057312,2016.438477 
	C959.069641,2014.241089 959.762756,2012.047485 960.372864,2008.502441 
	C969.467163,2008.502441 978.210022,2008.502441 987.062439,2008.502441 
	C987.062439,1996.765503 987.062439,1986.479370 985.871826,1974.842896 
M2380.065674,1946.745605 
	C2371.133057,1946.745605 2362.200684,1946.745605 2352.865479,1946.745605 
	C2351.495117,1950.340088 2349.894531,1953.648926 2348.965332,1957.136719 
	C2345.777344,1969.104858 2340.886230,1979.560303 2328.373779,1984.676636 
	C2326.408936,1985.480103 2324.352539,1988.489136 2324.132324,1990.660400 
	C2323.543945,1996.458496 2323.931885,2002.355835 2323.931885,2008.265503 
	C2329.465088,2008.933594 2333.603516,2009.433228 2338.473145,2010.021118 
	C2338.473145,2013.963989 2338.471680,2017.243652 2338.473389,2020.523193 
	C2338.481445,2036.522583 2338.054443,2052.538818 2338.675049,2068.514404 
	C2339.007568,2077.075439 2340.310303,2085.805420 2342.670654,2094.030518 
	C2345.837646,2105.067627 2353.934082,2112.060303 2365.166748,2114.641846 
	C2379.534424,2117.943848 2393.686279,2116.708008 2407.198975,2110.704834 
	C2409.243896,2109.796387 2411.821777,2107.209229 2411.920166,2105.284668 
	C2412.406250,2095.772949 2412.141357,2086.223145 2412.141357,2075.924561 
	C2406.598145,2077.342773 2402.480225,2078.624512 2398.271240,2079.428223 
	C2387.547607,2081.475830 2382.239258,2077.421387 2382.080566,2066.470459 
	C2381.824707,2048.808594 2382.013672,2031.139893 2382.099365,2013.474609 
	C2382.105225,2012.248169 2382.813232,2011.025146 2383.538086,2008.698242 
	C2392.326172,2008.698242 2401.147461,2008.698242 2410.049561,2008.698242 
	C2410.049561,1996.664551 2410.049561,1985.845825 2410.049561,1973.887207 
	C2400.498291,1973.887207 2391.666748,1973.887207 2381.393066,1973.887207 
	C2381.393066,1964.506592 2381.393066,1956.257080 2380.065674,1946.745605 
M1757.374023,2007.000000 
	C1757.208740,1979.378906 1757.045288,1951.757812 1756.852661,1924.136841 
	C1756.850830,1923.875610 1756.476685,1923.603027 1756.249512,1923.360840 
	C1756.030518,1923.127319 1755.776489,1922.926392 1755.591431,1922.759277 
	C1742.012207,1922.759277 1728.495239,1922.759277 1715.105103,1922.759277 
	C1712.838501,1935.824219 1713.508789,2106.642822 1715.723145,2113.239258 
	C1729.261475,2113.239258 1742.771729,2113.239258 1757.373169,2113.239258 
	C1757.373169,2078.091553 1757.373169,2043.545776 1757.374023,2007.000000 
M1671.668213,2108.288818 
	C1671.536255,2064.404785 1671.405151,2020.520874 1671.254639,1976.636841 
	C1671.253662,1976.348633 1671.015137,1976.039185 1670.831177,1975.779663 
	C1670.640869,1975.511108 1670.381836,1975.291138 1670.153076,1975.049805 
	C1657.580200,1973.285767 1635.696045,1973.780151 1629.022095,1975.979004 
	C1627.247192,1992.891479 1627.999023,2106.095459 1630.037476,2113.475586 
	C1632.533325,2113.599121 1635.152710,2113.830811 1637.772949,2113.840576 
	C1647.416626,2113.875977 1657.067139,2114.012695 1666.698608,2113.661133 
	C1668.371338,2113.600098 1669.964478,2111.360840 1671.668213,2108.288818 
M1637.109131,1958.304932 
	C1644.366577,1961.706787 1651.850220,1961.936768 1659.463013,1959.795410 
	C1667.377686,1957.569092 1671.754761,1952.158447 1672.771729,1944.148560 
	C1673.813965,1935.939819 1673.087646,1928.032837 1665.930420,1922.475342 
	C1658.380493,1916.613281 1642.595093,1916.473511 1634.743408,1922.155884 
	C1632.678467,1923.650391 1630.812134,1925.803101 1629.623779,1928.058838 
	C1624.467041,1937.847290 1627.085693,1949.903320 1637.109131,1958.304932 
z"/>
<path fill="#7735DC" opacity="1.000000" stroke="none" 
	d="
M919.016296,1669.999756 
	C919.016968,1470.438232 919.016968,1271.876709 919.016968,1072.743408 
	C994.188171,1072.743408 1068.019165,1072.743408 1142.963135,1072.743408 
	C1180.610229,1212.248169 1218.230713,1351.655273 1255.851318,1491.062378 
	C1256.644897,1491.099487 1257.438354,1491.136597 1258.231812,1491.173706 
	C1265.751831,1468.373657 1271.054321,1444.902100 1277.570801,1421.806030 
	C1284.081421,1398.730225 1290.003052,1375.488403 1296.239990,1352.334839 
	C1302.563599,1328.858887 1308.973755,1305.406128 1315.314819,1281.934814 
	C1321.569702,1258.782593 1327.773804,1235.616577 1334.021851,1212.462402 
	C1340.356201,1188.988403 1346.720947,1165.522705 1353.065186,1142.051392 
	C1359.306152,1118.962402 1365.538086,1095.870972 1371.809326,1072.650146 
	C1445.900879,1072.650146 1519.105103,1072.650146 1593.030640,1072.650146 
	C1593.030640,1293.284058 1593.030640,1513.438110 1593.027832,1733.592529 
	C1585.810791,1735.766602 1448.567017,1736.142822 1437.822510,1733.807739 
	C1437.822510,1683.758911 1437.805908,1633.496094 1437.827271,1583.233276 
	C1437.848633,1532.568604 1437.206787,1481.890991 1438.148438,1431.243042 
	C1439.079102,1381.191772 1441.626465,1331.170410 1442.273682,1280.901489 
	C1435.411621,1296.669312 1431.995239,1313.371948 1427.525024,1329.741211 
	C1422.874756,1346.771240 1418.447266,1363.862061 1413.907959,1380.922485 
	C1409.454468,1397.661011 1404.953857,1414.387085 1400.527344,1431.132812 
	C1396.101196,1447.877197 1391.761719,1464.644409 1387.346436,1481.391724 
	C1382.845825,1498.462769 1378.301392,1515.521973 1373.772095,1532.585327 
	C1369.328125,1549.327026 1364.867920,1566.064575 1360.435059,1582.809326 
	C1356.002075,1599.553589 1351.598145,1616.305420 1347.174561,1633.052246 
	C1342.666626,1650.118042 1338.174316,1667.188232 1333.630615,1684.244507 
	C1329.183716,1700.936523 1324.680542,1717.613403 1320.095215,1734.694702 
	C1275.591553,1734.694702 1231.737427,1734.694702 1186.438232,1734.694702 
	C1146.050415,1583.557495 1106.191284,1432.069824 1063.998169,1280.702148 
	C1063.525879,1282.035156 1062.568237,1283.399658 1062.652222,1284.696655 
	C1064.760254,1317.275024 1067.180054,1349.834351 1069.087280,1382.423950 
	C1069.982422,1397.720215 1070.232056,1413.071655 1070.253662,1428.398926 
	C1070.393677,1527.394897 1070.394165,1626.391357 1070.413208,1725.387573 
	C1070.413696,1728.305542 1070.114746,1731.223633 1069.924683,1734.704346 
	C1019.754944,1734.704346 970.221619,1734.704346 919.610352,1734.704346 
	C918.415955,1724.107422 919.174927,1713.523682 919.044495,1702.996704 
	C918.912415,1692.332520 919.015503,1681.665649 919.016296,1669.999756 
z"/>
<path fill="#7735DC" opacity="1.000000" stroke="none" 
	d="
M1879.995483,1735.304443 
	C1823.772827,1735.304077 1768.549194,1735.304077 1712.746826,1735.304077 
	C1712.746826,1513.801758 1712.746826,1293.680054 1712.746826,1072.985840 
	C1715.344727,1072.684937 1717.569336,1072.201660 1719.793579,1072.202393 
	C1787.453003,1072.223145 1855.120239,1071.684570 1922.768921,1072.569214 
	C1966.777832,1073.144775 2009.589233,1081.241821 2049.732666,1100.315918 
	C2103.084229,1125.665894 2141.461182,1165.824829 2166.077148,1219.260254 
	C2183.707275,1257.531494 2192.907715,1298.054565 2195.766113,1339.902222 
	C2197.421143,1364.132324 2198.260986,1388.518555 2197.613770,1412.785156 
	C2196.165283,1467.103149 2187.947998,1520.353516 2165.933594,1570.531128 
	C2128.278320,1656.358643 2063.307373,1708.845337 1971.472168,1727.990479 
	C1941.608887,1734.216064 1911.363159,1735.459839 1879.995483,1735.304443 
M1998.230713,1543.098755 
	C2010.437134,1522.858276 2017.376343,1500.710938 2021.724243,1477.663696 
	C2029.184448,1438.118408 2029.681763,1398.187012 2027.211060,1358.224243 
	C2025.626953,1332.603638 2021.670044,1307.272949 2012.675903,1282.986206 
	C2002.811523,1256.349854 1986.649902,1235.080811 1960.603516,1222.521973 
	C1934.097534,1209.741577 1905.796265,1211.187622 1876.892700,1212.129883 
	C1876.892700,1339.855835 1876.892700,1466.873413 1876.892700,1593.841675 
	C1928.479248,1599.098633 1971.325439,1585.477783 1998.230713,1543.098755 
z"/>
<path fill="#7736DC" opacity="1.000000" stroke="none" 
	d="
M764.000000,1346.200928 
	C780.566040,1346.200928 796.132141,1346.200928 812.266846,1346.200928 
	C812.266846,1392.205811 812.266846,1436.983887 812.266846,1482.850586 
	C758.057190,1482.850586 704.558472,1482.850586 649.979675,1482.850586 
	C649.979675,1567.093750 649.979675,1650.259888 649.979675,1733.045532 
	C642.171021,1735.720215 502.771851,1736.568359 487.660431,1734.400879 
	C485.709381,1727.034912 484.794525,1094.670410 486.705139,1074.064697 
	C486.932556,1073.824707 487.151123,1073.575562 487.389954,1073.347534 
	C487.626862,1073.121338 487.870392,1072.750244 488.134888,1072.728149 
	C490.778046,1072.507080 493.426147,1072.189819 496.072906,1072.189209 
	C605.365784,1072.162964 714.658691,1072.166748 823.741211,1072.166748 
	C826.222778,1079.644775 826.706360,1197.312744 824.256775,1209.060303 
	C766.704712,1209.060303 708.885010,1209.060303 650.330078,1209.060303 
	C650.330078,1255.041382 650.330078,1299.836304 650.330078,1344.492676 
	C650.721985,1344.864502 650.944824,1345.119629 651.210999,1345.315918 
	C651.467102,1345.504639 651.767517,1345.766968 652.049438,1345.768188 
	C689.032959,1345.926025 726.016479,1346.062866 764.000000,1346.200928 
z"/>
<path fill="#7736DC" opacity="1.000000" stroke="none" 
	d="
M2471.697998,1747.764160 
	C2446.941650,1789.017822 2418.581787,1826.631836 2387.455322,1860.738892 
	C2363.467285,1837.007568 2340.146973,1813.937134 2315.929932,1789.979370 
	C2317.758545,1787.547119 2320.097900,1784.030518 2322.834473,1780.856323 
	C2360.022705,1737.724854 2391.535889,1690.858521 2415.499023,1639.120361 
	C2439.581299,1587.124878 2455.570312,1532.660889 2464.304199,1476.029419 
	C2471.761475,1427.677490 2472.354736,1379.064209 2467.828369,1330.554443 
	C2462.478271,1273.218262 2448.312256,1217.818481 2426.397705,1164.469238 
	C2406.791504,1116.739136 2381.207764,1072.435913 2349.998779,1031.400513 
	C2340.729736,1019.212891 2330.669922,1007.627441 2321.001465,995.742371 
	C2319.151123,993.467773 2317.436523,991.082703 2314.866455,987.707581 
	C2337.112305,962.980042 2361.040283,940.460571 2384.171875,915.434265 
	C2387.920654,919.213867 2391.255859,922.262024 2394.233887,925.626282 
	C2441.345215,978.846069 2480.651611,1037.161987 2509.866943,1102.135864 
	C2539.730225,1168.549805 2558.946777,1237.905273 2567.573975,1310.157959 
	C2571.883301,1346.249146 2574.168945,1382.575562 2571.793457,1418.849854 
	C2569.073975,1460.381104 2564.085449,1501.711792 2554.739258,1542.408569 
	C2542.688232,1594.883911 2525.221191,1645.443970 2501.239258,1693.640503 
	C2492.197510,1711.811890 2481.820557,1729.318848 2471.697998,1747.764160 
z"/>
<path fill="#7736DC" opacity="1.000000" stroke="none" 
	d="
M2203.908447,1680.091064 
	C2196.856689,1673.003418 2190.303467,1666.417480 2183.107910,1659.185913 
	C2248.364258,1580.461914 2284.933350,1491.417236 2284.393066,1388.785278 
	C2283.856201,1286.816650 2250.067383,1197.071655 2183.400879,1119.296265 
	C2185.311279,1116.899536 2186.765381,1114.735107 2188.549316,1112.889648 
	C2205.685303,1095.164307 2222.874268,1077.490479 2240.056396,1059.809814 
	C2241.681885,1058.137329 2243.278809,1056.422729 2245.032227,1054.891479 
	C2252.389160,1048.467529 2256.171143,1048.514282 2262.653320,1055.952026 
	C2280.864502,1076.847900 2296.906494,1099.360474 2311.428223,1122.943848 
	C2346.829834,1180.436035 2368.275879,1242.946899 2380.037109,1309.291992 
	C2387.382080,1350.726074 2387.353516,1392.291626 2384.641602,1433.789917 
	C2379.781738,1508.152344 2357.016357,1577.308960 2319.330078,1641.572998 
	C2302.447021,1670.362183 2284.230957,1698.204468 2261.814941,1723.084351 
	C2259.831299,1725.286133 2257.724854,1727.377563 2254.939453,1730.289551 
	C2245.461426,1722.552246 2237.648682,1713.559937 2229.166504,1705.317993 
	C2220.802490,1697.190430 2212.651367,1688.843628 2203.908447,1680.091064 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M1104.237183,2113.840820 
	C1099.634766,2113.840576 1095.992432,2113.840576 1092.541870,2113.840576 
	C1090.158325,2106.546875 1089.505249,1947.811523 1091.729736,1934.499390 
	C1111.415771,1934.499390 1131.219971,1934.499390 1151.835327,1934.499390 
	C1156.758667,1952.863159 1161.623779,1971.172119 1166.581543,1989.456055 
	C1171.499268,2007.592041 1176.510498,2025.702759 1181.480591,2043.824585 
	C1182.211792,2043.755493 1182.942871,2043.686279 1183.673950,2043.617065 
	C1193.470947,2007.407837 1203.267822,1971.198486 1213.198730,1934.494263 
	C1233.132446,1934.494263 1252.649780,1934.494263 1272.178589,1934.494263 
	C1274.255005,1941.448975 1274.750366,2101.347900 1272.615601,2113.227051 
	C1259.251831,2113.227051 1245.769409,2113.227051 1231.661499,2113.227051 
	C1231.661499,2075.100098 1231.661499,2037.261719 1231.661499,1999.423340 
	C1219.357056,2036.860107 1210.505859,2075.279785 1199.481201,2113.384277 
	C1187.403931,2113.384277 1175.829224,2113.384277 1163.073120,2113.384277 
	C1153.299683,2076.730957 1143.485596,2039.924683 1133.671387,2003.118530 
	C1133.068115,2003.187256 1132.464722,2003.256104 1131.861450,2003.324829 
	C1131.861450,2039.774902 1131.861450,2076.224854 1131.861450,2113.841064 
	C1122.333374,2113.841064 1113.765259,2113.841064 1104.237183,2113.840820 
z"/>
<path fill="#7838DC" opacity="1.000000" stroke="none" 
	d="
M2144.896973,1995.324219 
	C2148.186035,2016.454834 2147.966553,2036.651733 2142.491943,2056.537842 
	C2132.603516,2092.454834 2105.963623,2113.138916 2068.838623,2113.750244 
	C2053.195801,2114.007812 2037.545654,2113.833252 2021.898804,2113.802002 
	C2019.959351,2113.798096 2018.020386,2113.467529 2015.465454,2113.231445 
	C2015.465454,2053.528076 2015.465454,1994.143311 2015.465454,1933.849121 
	C2023.689575,1933.849121 2031.288330,1933.841309 2038.887207,1933.850830 
	C2049.873291,1933.864502 2060.863281,1933.702026 2071.844482,1933.945801 
	C2111.801025,1934.832520 2136.386963,1955.333252 2144.896973,1995.324219 
M2059.757812,1985.187744 
	C2059.757812,2015.015625 2059.757812,2044.843628 2059.757812,2074.459717 
	C2079.468994,2077.508789 2092.671387,2068.720215 2097.562500,2049.369141 
	C2101.856689,2032.380005 2101.607422,2015.117920 2098.323486,1997.926758 
	C2097.828369,1995.333618 2097.038086,1992.753052 2096.040283,1990.306885 
	C2089.950928,1975.376221 2076.551025,1969.044434 2059.758057,1973.657959 
	C2059.758057,1976.695190 2059.758057,1979.972778 2059.757812,1985.187744 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M1551.253418,1972.074341 
	C1563.467285,1973.800659 1572.121216,1979.946899 1577.398804,1989.750488 
	C1581.606079,1997.565796 1585.331665,2006.024292 1587.188110,2014.645874 
	C1591.798584,2036.059204 1591.521484,2057.699707 1585.822266,2078.960693 
	C1584.122070,2085.303467 1581.248535,2091.452637 1578.133789,2097.274414 
	C1565.224365,2121.403320 1531.842773,2121.531738 1518.690918,2104.865234 
	C1517.520752,2103.382568 1515.824219,2102.314941 1514.241699,2100.941406 
	C1513.109009,2101.800049 1511.914429,2102.224365 1511.685547,2102.952148 
	C1508.673462,2112.528809 1502.108765,2115.206299 1492.587280,2113.987793 
	C1486.388916,2113.194824 1480.006592,2113.838623 1473.901855,2113.838623 
	C1471.502319,2106.452393 1470.944824,1934.755493 1473.203979,1922.735718 
	C1486.607666,1922.735718 1500.132690,1922.735718 1515.065674,1922.735718 
	C1515.514282,1944.812500 1515.901855,1966.880737 1513.835693,1990.190186 
	C1519.930664,1984.814453 1524.669922,1979.852905 1530.178467,1975.987793 
	C1536.108276,1971.827026 1543.306885,1971.589844 1551.253418,1972.074341 
M1515.784546,2058.288330 
	C1517.070312,2063.392822 1517.503540,2068.927979 1519.820312,2073.511475 
	C1524.562744,2082.893066 1536.296875,2082.913086 1541.306274,2073.966064 
	C1542.427368,2071.963623 1543.338867,2069.741211 1543.831909,2067.506592 
	C1547.162720,2052.410645 1547.268677,2037.259888 1544.229370,2022.096191 
	C1544.034058,2021.122314 1543.820435,2020.142944 1543.503784,2019.203735 
	C1541.043457,2011.904175 1536.877197,2008.111572 1531.198853,2007.957886 
	C1524.705322,2007.782349 1520.087891,2011.304077 1517.620728,2018.864258 
	C1516.605347,2021.975464 1515.926392,2025.324097 1515.835815,2028.585205 
	C1515.578003,2037.863403 1515.701660,2047.151978 1515.784546,2058.288330 
z"/>
<path fill="#7838DC" opacity="1.000000" stroke="none" 
	d="
M2281.625488,1986.481445 
	C2289.850830,1996.110962 2292.631104,2007.076782 2292.764648,2018.549927 
	C2293.109863,2048.163330 2292.944336,2077.782715 2292.903564,2107.399902 
	C2292.900879,2109.306885 2292.223389,2111.212646 2291.798584,2113.440918 
	C2281.909912,2113.440918 2272.344727,2113.440918 2262.442139,2113.440918 
	C2259.849609,2107.994141 2257.341064,2102.723877 2253.982422,2095.667480 
	C2250.791748,2099.382080 2248.633301,2102.228516 2246.139893,2104.742432 
	C2233.719971,2117.263672 2218.631836,2118.563721 2202.677979,2114.151367 
	C2193.838135,2111.706543 2188.034668,2105.094971 2184.397217,2096.855713 
	C2178.698975,2083.947510 2177.668945,2070.435059 2180.852051,2056.784912 
	C2183.981689,2043.363525 2192.670410,2034.100342 2205.684326,2030.500854 
	C2216.466797,2027.518555 2227.868652,2026.753174 2239.012695,2025.117432 
	C2242.217285,2024.647217 2245.478760,2024.564697 2248.598877,2024.313721 
	C2249.527100,2009.316162 2244.685059,2004.064209 2231.146484,2005.695190 
	C2225.294678,2006.400024 2219.586670,2008.559814 2213.910889,2010.365601 
	C2210.484375,2011.455811 2207.245117,2013.135864 2202.747070,2015.051270 
	C2198.887451,2004.837036 2195.294678,1995.329224 2191.333008,1984.844849 
	C2194.896729,1982.994385 2197.934082,1981.139038 2201.178955,1979.779175 
	C2217.804199,1972.813232 2235.226562,1970.792847 2253.034180,1972.339111 
	C2263.988770,1973.290161 2273.554688,1977.726929 2281.625488,1986.481445 
M2234.704346,2083.262939 
	C2248.447021,2078.764160 2252.883057,2068.842773 2248.137207,2051.210205 
	C2242.485596,2052.266357 2236.484863,2052.646240 2231.048340,2054.597900 
	C2224.727539,2056.866699 2221.942383,2063.344971 2222.562744,2071.053467 
	C2223.099854,2077.728760 2225.861084,2082.509766 2234.704346,2083.262939 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M2438.468262,2079.193359 
	C2438.352783,2073.976562 2438.014160,2069.621826 2438.359619,2065.321533 
	C2439.677490,2048.928711 2447.604248,2037.210693 2463.070801,2030.812866 
	C2473.779785,2026.382935 2485.104248,2025.940674 2496.392578,2025.115601 
	C2499.979980,2024.853516 2503.571289,2024.647583 2506.969482,2024.428223 
	C2509.181396,2010.466064 2504.118164,2004.248413 2491.328857,2005.629639 
	C2484.810547,2006.333496 2478.445801,2008.717529 2472.095947,2010.622437 
	C2468.980469,2011.557007 2466.056641,2013.130371 2462.036865,2014.844849 
	C2457.435059,2004.851929 2454.004883,1995.016724 2450.502930,1984.986084 
	C2458.842773,1978.975220 2467.774902,1976.154053 2476.883545,1974.609009 
	C2486.353027,1973.002686 2496.062744,1972.057739 2505.660645,1972.107788 
	C2532.945312,1972.250610 2549.814209,1988.536865 2551.620850,2015.815674 
	C2551.972656,2021.126831 2551.814941,2026.474609 2551.818848,2031.805420 
	C2551.836426,2055.464355 2551.827148,2079.123535 2551.827637,2102.782471 
	C2551.827637,2106.033203 2551.827637,2109.284180 2551.827637,2113.183594 
	C2541.323975,2113.183594 2531.807617,2113.183594 2521.281982,2113.183594 
	C2518.891846,2108.094971 2516.250488,2102.471191 2512.322021,2094.107910 
	C2510.981201,2096.939941 2510.495361,2098.622314 2509.514648,2099.934814 
	C2500.613770,2111.844971 2488.561768,2116.723145 2473.855225,2116.280029 
	C2458.227783,2115.808594 2447.295654,2108.390869 2442.027100,2093.601562 
	C2440.472412,2089.236572 2439.693359,2084.595215 2438.468262,2079.193359 
M2483.268799,2061.119141 
	C2482.771973,2063.687256 2481.936768,2066.243164 2481.841309,2068.826172 
	C2481.551514,2076.680176 2483.782227,2080.659912 2489.073242,2082.507324 
	C2494.410889,2084.371094 2500.583252,2082.260986 2504.642090,2076.638916 
	C2510.056396,2069.139404 2509.139404,2060.787598 2508.390869,2051.867676 
	C2498.618164,2052.104248 2489.863281,2051.269775 2483.268799,2061.119141 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M748.066833,2091.947266 
	C748.067017,2099.511475 748.067017,2106.084229 748.067017,2113.229492 
	C737.459778,2113.229492 727.944031,2113.229492 717.702759,2113.229492 
	C715.201355,2107.935791 712.550415,2102.325928 709.612427,2096.108398 
	C707.908203,2097.929688 706.701660,2099.028320 705.718201,2100.299561 
	C696.358154,2112.397217 683.867249,2116.910889 668.866333,2116.287109 
	C655.988342,2115.751709 646.425781,2110.058105 640.734192,2098.748779 
	C633.391602,2084.158691 632.258850,2068.750977 637.269958,2053.155518 
	C641.014893,2041.500488 649.416626,2033.719116 660.842651,2030.573242 
	C671.323853,2027.687622 682.358093,2026.778564 693.171753,2025.152344 
	C696.410828,2024.665283 699.727783,2024.695312 702.851135,2024.498413 
	C706.068604,2011.813110 701.056030,2004.541870 689.151306,2005.561279 
	C681.992126,2006.174438 674.978027,2008.800049 667.977112,2010.795898 
	C664.882874,2011.677979 661.983032,2013.241699 658.097412,2014.872803 
	C654.186157,2004.657837 650.544556,1995.147095 646.535828,1984.677612 
	C650.390869,1982.747192 653.746643,1980.754028 657.328064,1979.327148 
	C673.400085,1972.923950 690.175781,1970.830322 707.320496,1972.231201 
	C731.304382,1974.190552 746.985779,1990.917603 747.824707,2016.044434 
	C748.446594,2034.670654 748.027588,2053.331543 748.064697,2071.977051 
	C748.077332,2078.303223 748.066650,2084.629639 748.066833,2091.947266 
M700.679626,2077.426025 
	C706.035767,2069.838379 706.237488,2061.492432 704.622192,2051.719971 
	C699.381592,2052.163818 694.365784,2051.929443 689.712830,2053.116943 
	C679.208374,2055.797607 674.259155,2068.850342 679.849548,2078.070557 
	C682.384338,2082.251465 686.357971,2083.998779 690.914429,2082.890869 
	C694.002380,2082.140137 696.764160,2080.047852 700.679626,2077.426025 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M1324.098145,1998.277466 
	C1338.663818,1974.703003 1360.493042,1969.182129 1385.134521,1972.729858 
	C1408.454346,1976.087158 1423.306885,1990.652832 1430.194336,2012.888672 
	C1437.096069,2035.169800 1436.676636,2057.631592 1428.543823,2079.525146 
	C1420.228149,2101.911377 1404.080322,2114.497314 1379.835571,2116.135742 
	C1353.971313,2117.883545 1333.745605,2108.483643 1322.626099,2084.668945 
	C1309.356201,2056.249268 1310.035522,2027.330811 1324.098145,1998.277466 
M1388.336548,2068.598389 
	C1391.842773,2053.777100 1391.049438,2038.827881 1389.475220,2023.890137 
	C1389.166382,2020.958984 1388.168213,2018.014526 1387.013062,2015.276611 
	C1384.752075,2009.916870 1380.561523,2006.754150 1374.691406,2006.560059 
	C1368.801392,2006.365112 1363.897095,2009.096436 1362.010864,2014.567993 
	C1355.175049,2034.396606 1354.777832,2054.592773 1362.588257,2074.080811 
	C1366.560669,2083.992676 1378.917236,2084.231689 1385.402344,2075.567871 
	C1386.567993,2074.010742 1387.120483,2071.994141 1388.336548,2068.598389 
z"/>
<path fill="#793ADC" opacity="1.000000" stroke="none" 
	d="
M1906.771118,2057.109131 
	C1883.632080,2057.326660 1861.361084,2057.326660 1839.271118,2057.326660 
	C1836.943237,2070.709473 1843.821167,2080.626709 1856.327148,2082.279297 
	C1867.736816,2083.787109 1878.932129,2082.422852 1889.645020,2077.928467 
	C1893.226807,2076.425781 1896.845459,2075.010742 1901.900635,2072.968262 
	C1901.900635,2084.016846 1902.167358,2093.569580 1901.681641,2103.083740 
	C1901.581299,2105.049316 1899.053223,2107.787354 1897.012085,2108.620117 
	C1873.600586,2118.169678 1849.770508,2120.064453 1826.078735,2110.080811 
	C1812.009033,2104.152100 1803.548950,2092.443115 1799.233765,2078.185303 
	C1792.090332,2054.582764 1792.171143,2030.737549 1800.313965,2007.412109 
	C1807.576904,1986.606689 1822.738525,1974.584473 1844.760620,1972.463501 
	C1867.350098,1970.287964 1887.510742,1975.253052 1900.108398,1996.251221 
	C1903.631592,2002.123535 1906.489380,2009.012817 1907.350708,2015.730713 
	C1908.952637,2028.223511 1908.911621,2040.931274 1909.390747,2053.555664 
	C1909.432007,2054.644043 1908.251953,2055.778564 1906.771118,2057.109131 
M1854.758667,2004.020142 
	C1841.450195,2006.234497 1836.351074,2013.786987 1839.270752,2027.318359 
	C1849.069458,2027.318359 1858.921631,2027.318359 1868.701904,2027.318359 
	C1870.168823,2014.580566 1866.816650,2008.488159 1854.758667,2004.020142 
z"/>
<path fill="#7838DC" opacity="1.000000" stroke="none" 
	d="
M598.947693,2037.164429 
	C598.612915,2040.297852 598.281006,2042.527710 597.835571,2045.520264 
	C583.873657,2045.520264 570.304932,2045.520264 555.374268,2045.520264 
	C554.064331,2068.317871 555.560120,2090.463623 554.477295,2113.148438 
	C539.683228,2113.148438 525.581116,2113.148438 510.786652,2113.148438 
	C510.786652,2053.767090 510.786652,1994.653564 510.791443,1935.538818 
	C517.705017,1933.445068 588.873413,1932.916626 601.600159,1934.978516 
	C601.600159,1946.611816 601.600159,1958.359375 601.600159,1971.162598 
	C586.223816,1971.162598 571.371216,1971.162598 555.697510,1971.162598 
	C555.697510,1983.625244 555.697510,1995.062134 555.697510,2007.647705 
	C569.572510,2007.647705 583.416748,2007.647705 598.637573,2007.647705 
	C598.749695,2017.897705 598.850098,2027.079346 598.947693,2037.164429 
z"/>
<path fill="#793ADC" opacity="1.000000" stroke="none" 
	d="
M832.773132,2077.153564 
	C835.732178,2070.296143 831.745728,2067.004639 827.303040,2064.476562 
	C820.657715,2060.694824 813.666382,2057.527588 806.904480,2053.943604 
	C789.112305,2044.512817 781.116699,2029.495239 782.436462,2009.612183 
	C783.564514,1992.617676 792.134155,1980.642090 808.478821,1975.397095 
	C831.330261,1968.063965 853.021362,1971.926392 874.903564,1983.829346 
	C871.294861,1994.062256 867.823975,2003.904053 864.120361,2014.406006 
	C858.624634,2012.076660 854.248291,2009.632202 849.553894,2008.379517 
	C843.521606,2006.769897 837.262329,2005.714722 831.040588,2005.278076 
	C828.966675,2005.132446 825.107056,2007.414795 824.902222,2008.962891 
	C824.582397,2011.380127 826.090881,2014.685669 827.907043,2016.573975 
	C830.129822,2018.885254 833.462097,2020.134277 836.326477,2021.822510 
	C843.775940,2026.213013 851.634827,2030.035889 858.608582,2035.082520 
	C878.130005,2049.209473 882.829102,2078.438477 869.196289,2098.332520 
	C862.118530,2108.660645 851.663574,2113.696777 839.773315,2115.274170 
	C823.476746,2117.436279 807.199463,2116.732422 791.344910,2111.726562 
	C782.633667,2108.976074 782.529602,2108.839355 782.472595,2100.018066 
	C782.411072,2090.502686 782.458679,2080.986816 782.458679,2070.287842 
	C788.144714,2072.495605 792.285461,2074.606689 796.679382,2075.700195 
	C804.706177,2077.698242 812.826843,2079.598389 821.022888,2080.509766 
	C824.623169,2080.909912 828.504272,2078.783936 832.773132,2077.153564 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M986.467163,1975.518066 
	C987.062439,1986.479370 987.062439,1996.765503 987.062439,2008.502441 
	C978.210022,2008.502441 969.467163,2008.502441 960.372864,2008.502441 
	C959.762756,2012.047485 959.069641,2014.241089 959.057312,2016.438477 
	C958.965637,2032.770264 958.837524,2049.105225 959.065186,2065.434326 
	C959.242004,2078.120361 964.636536,2082.039795 977.045532,2079.022461 
	C980.544128,2078.171875 983.994568,2077.123291 988.229004,2075.955566 
	C990.313477,2086.352051 989.239441,2096.223633 988.889954,2106.002197 
	C988.832825,2107.600586 986.226196,2109.694580 984.340210,2110.547852 
	C969.828247,2117.115234 954.744385,2118.291016 939.480591,2113.824707 
	C929.601379,2110.934082 922.429871,2104.359619 919.631775,2094.365479 
	C917.590027,2087.072510 916.141602,2079.405029 915.901184,2071.857910 
	C915.349854,2054.542725 915.698303,2037.198975 915.682495,2019.866821 
	C915.679565,2016.625488 915.682068,2013.384033 915.682068,2009.876831 
	C910.277649,2009.300537 906.071106,2008.851929 900.523071,2008.260254 
	C900.523071,2002.124756 900.109070,1996.211304 900.739319,1990.411499 
	C900.971375,1988.276367 903.261353,1985.436523 905.308289,1984.579224 
	C917.723755,1979.379639 922.796082,1969.026367 925.828491,1956.984985 
	C926.395325,1954.734009 926.905701,1952.219482 928.277527,1950.488892 
	C929.722717,1948.665649 932.102539,1946.580322 934.189758,1946.448853 
	C941.796509,1945.969971 949.451660,1946.259766 958.299011,1946.259766 
	C958.725098,1955.565063 959.125061,1964.299316 959.552551,1973.635376 
	C968.766235,1974.058105 977.319031,1974.450562 986.467163,1975.518066 
z"/>
<path fill="#7939DC" opacity="1.000000" stroke="none" 
	d="
M2380.729492,1947.376587 
	C2381.393066,1956.257080 2381.393066,1964.506592 2381.393066,1973.887207 
	C2391.666748,1973.887207 2400.498291,1973.887207 2410.049561,1973.887207 
	C2410.049561,1985.845825 2410.049561,1996.664551 2410.049561,2008.698242 
	C2401.147461,2008.698242 2392.326172,2008.698242 2383.538086,2008.698242 
	C2382.813232,2011.025146 2382.105225,2012.248169 2382.099365,2013.474609 
	C2382.013672,2031.139893 2381.824707,2048.808594 2382.080566,2066.470459 
	C2382.239258,2077.421387 2387.547607,2081.475830 2398.271240,2079.428223 
	C2402.480225,2078.624512 2406.598145,2077.342773 2412.141357,2075.924561 
	C2412.141357,2086.223145 2412.406250,2095.772949 2411.920166,2105.284668 
	C2411.821777,2107.209229 2409.243896,2109.796387 2407.198975,2110.704834 
	C2393.686279,2116.708008 2379.534424,2117.943848 2365.166748,2114.641846 
	C2353.934082,2112.060303 2345.837646,2105.067627 2342.670654,2094.030518 
	C2340.310303,2085.805420 2339.007568,2077.075439 2338.675049,2068.514404 
	C2338.054443,2052.538818 2338.481445,2036.522583 2338.473389,2020.523193 
	C2338.471680,2017.243652 2338.473145,2013.963989 2338.473145,2010.021118 
	C2333.603516,2009.433228 2329.465088,2008.933594 2323.931885,2008.265503 
	C2323.931885,2002.355835 2323.543945,1996.458496 2324.132324,1990.660400 
	C2324.352539,1988.489136 2326.408936,1985.480103 2328.373779,1984.676636 
	C2340.886230,1979.560303 2345.777344,1969.104858 2348.965332,1957.136719 
	C2349.894531,1953.648926 2351.495117,1950.340088 2352.865479,1946.745605 
	C2362.200684,1946.745605 2371.133057,1946.745605 2380.729492,1947.376587 
z"/>
<path fill="#7A3BDC" opacity="1.000000" stroke="none" 
	d="
M1757.373535,2008.000000 
	C1757.373169,2043.545776 1757.373169,2078.091553 1757.373169,2113.239258 
	C1742.771729,2113.239258 1729.261475,2113.239258 1715.723145,2113.239258 
	C1713.508789,2106.642822 1712.838501,1935.824219 1715.105103,1922.759277 
	C1728.495239,1922.759277 1742.012207,1922.759277 1755.591431,1922.759277 
	C1755.776489,1922.926392 1756.030518,1923.127319 1756.249512,1923.360840 
	C1756.476685,1923.603027 1756.850830,1923.875610 1756.852661,1924.136841 
	C1757.045288,1951.757812 1757.208740,1979.378906 1757.373535,2008.000000 
z"/>
<path fill="#7B3CDC" opacity="1.000000" stroke="none" 
	d="
M1671.631348,2109.208008 
	C1669.964478,2111.360840 1668.371338,2113.600098 1666.698608,2113.661133 
	C1657.067139,2114.012695 1647.416626,2113.875977 1637.772949,2113.840576 
	C1635.152710,2113.830811 1632.533325,2113.599121 1630.037476,2113.475586 
	C1627.999023,2106.095459 1627.247192,1992.891479 1629.022095,1975.979004 
	C1635.696045,1973.780151 1657.580200,1973.285767 1670.153076,1975.049805 
	C1670.381836,1975.291138 1670.640869,1975.511108 1670.831177,1975.779663 
	C1671.015137,1976.039185 1671.253662,1976.348633 1671.254639,1976.636841 
	C1671.405151,2020.520874 1671.536255,2064.404785 1671.631348,2109.208008 
z"/>
<path fill="#7A3BDC" opacity="1.000000" stroke="none" 
	d="
M1636.428467,1957.957031 
	C1627.085693,1949.903320 1624.467041,1937.847290 1629.623779,1928.058838 
	C1630.812134,1925.803101 1632.678467,1923.650391 1634.743408,1922.155884 
	C1642.595093,1916.473511 1658.380493,1916.613281 1665.930420,1922.475342 
	C1673.087646,1928.032837 1673.813965,1935.939819 1672.771729,1944.148560 
	C1671.754761,1952.158447 1667.377686,1957.569092 1659.463013,1959.795410 
	C1651.850220,1961.936768 1644.366577,1961.706787 1636.428467,1957.957031 
z"/>
<path fill="#FEFDFF" opacity="1.000000" stroke="none" 
	d="
M1997.882812,1543.735596 
	C1971.325439,1585.477783 1928.479248,1599.098633 1876.892700,1593.841675 
	C1876.892700,1466.873413 1876.892700,1339.855835 1876.892700,1212.129883 
	C1905.796265,1211.187622 1934.097534,1209.741577 1960.603516,1222.521973 
	C1986.649902,1235.080811 2002.811523,1256.349854 2012.675903,1282.986206 
	C2021.670044,1307.272949 2025.626953,1332.603638 2027.211060,1358.224243 
	C2029.681763,1398.187012 2029.184448,1438.118408 2021.724243,1477.663696 
	C2017.376343,1500.710938 2010.437134,1522.858276 1997.882812,1543.735596 
z"/>
<path fill="#FCFAFE" opacity="1.000000" stroke="none" 
	d="
M2059.757812,1984.218994 
	C2059.758057,1979.972778 2059.758057,1976.695190 2059.758057,1973.657959 
	C2076.551025,1969.044434 2089.950928,1975.376221 2096.040283,1990.306885 
	C2097.038086,1992.753052 2097.828369,1995.333618 2098.323486,1997.926758 
	C2101.607422,2015.117920 2101.856689,2032.380005 2097.562500,2049.369141 
	C2092.671387,2068.720215 2079.468994,2077.508789 2059.757812,2074.459717 
	C2059.757812,2044.843628 2059.757812,2015.015625 2059.757812,1984.218994 
z"/>
<path fill="#FBF9FE" opacity="1.000000" stroke="none" 
	d="
M1515.729980,2057.362305 
	C1515.701660,2047.151978 1515.578003,2037.863403 1515.835815,2028.585205 
	C1515.926392,2025.324097 1516.605347,2021.975464 1517.620728,2018.864258 
	C1520.087891,2011.304077 1524.705322,2007.782349 1531.198853,2007.957886 
	C1536.877197,2008.111572 1541.043457,2011.904175 1543.503784,2019.203735 
	C1543.820435,2020.142944 1544.034058,2021.122314 1544.229370,2022.096191 
	C1547.268677,2037.259888 1547.162720,2052.410645 1543.831909,2067.506592 
	C1543.338867,2069.741211 1542.427368,2071.963623 1541.306274,2073.966064 
	C1536.296875,2082.913086 1524.562744,2082.893066 1519.820312,2073.511475 
	C1517.503540,2068.927979 1517.070312,2063.392822 1515.729980,2057.362305 
z"/>
<path fill="#F7F1FD" opacity="1.000000" stroke="none" 
	d="
M2233.958008,2083.264648 
	C2225.861084,2082.509766 2223.099854,2077.728760 2222.562744,2071.053467 
	C2221.942383,2063.344971 2224.727539,2056.866699 2231.048340,2054.597900 
	C2236.484863,2052.646240 2242.485596,2052.266357 2248.137207,2051.210205 
	C2252.883057,2068.842773 2248.447021,2078.764160 2233.958008,2083.264648 
z"/>
<path fill="#F7F1FD" opacity="1.000000" stroke="none" 
	d="
M2483.636230,2060.399902 
	C2489.863281,2051.269775 2498.618164,2052.104248 2508.390869,2051.867676 
	C2509.139404,2060.787598 2510.056396,2069.139404 2504.642090,2076.638916 
	C2500.583252,2082.260986 2494.410889,2084.371094 2489.073242,2082.507324 
	C2483.782227,2080.659912 2481.551514,2076.680176 2481.841309,2068.826172 
	C2481.936768,2066.243164 2482.771973,2063.687256 2483.636230,2060.399902 
z"/>
<path fill="#F7F1FD" opacity="1.000000" stroke="none" 
	d="
M700.175781,2077.990723 
	C696.764160,2080.047852 694.002380,2082.140137 690.914429,2082.890869 
	C686.357971,2083.998779 682.384338,2082.251465 679.849548,2078.070557 
	C674.259155,2068.850342 679.208374,2055.797607 689.712830,2053.116943 
	C694.365784,2051.929443 699.381592,2052.163818 704.622192,2051.719971 
	C706.237488,2061.492432 706.035767,2069.838379 700.175781,2077.990723 
z"/>
<path fill="#FBF9FE" opacity="1.000000" stroke="none" 
	d="
M1388.146240,2069.394043 
	C1387.120483,2071.994141 1386.567993,2074.010742 1385.402344,2075.567871 
	C1378.917236,2084.231689 1366.560669,2083.992676 1362.588257,2074.080811 
	C1354.777832,2054.592773 1355.175049,2034.396606 1362.010864,2014.567993 
	C1363.897095,2009.096436 1368.801392,2006.365112 1374.691406,2006.560059 
	C1380.561523,2006.754150 1384.752075,2009.916870 1387.013062,2015.276611 
	C1388.168213,2018.014526 1389.166382,2020.958984 1389.475220,2023.890137 
	C1391.049438,2038.827881 1391.842773,2053.777100 1388.146240,2069.394043 
z"/>
<path fill="#F7F1FD" opacity="1.000000" stroke="none" 
	d="
M1855.548584,2004.086914 
	C1866.816650,2008.488159 1870.168823,2014.580566 1868.701904,2027.318359 
	C1858.921631,2027.318359 1849.069458,2027.318359 1839.270752,2027.318359 
	C1836.351074,2013.786987 1841.450195,2006.234497 1855.548584,2004.086914 
z"/>




</g>
</svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
